/**
 * @generated SignedSource<<643990f1434b4344ba71aa8baf333b4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductItem_cartItem$data = {
  readonly id: string;
  readonly qty: number | null | undefined;
  readonly " $fragmentType": "ProductItem_cartItem";
};
export type ProductItem_cartItem$key = {
  readonly " $data"?: ProductItem_cartItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductItem_cartItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductItem_cartItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qty",
      "storageKey": null
    }
  ],
  "type": "CartItem",
  "abstractKey": null
};

(node as any).hash = "e953903284d349f3d558f34f20c4771c";

export default node;
