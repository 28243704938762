/**
 * @generated SignedSource<<d6491ee86000a371ec9424b91560711f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RateProductInput = {
  comment?: string | null | undefined;
  isAnonymous?: boolean | null | undefined;
  productId: string;
  rating: number;
};
export type RateProductMutation$variables = {
  input: RateProductInput;
};
export type RateProductMutation$data = {
  readonly rateProduct: {
    readonly viewer: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type RateProductMutation = {
  response: RateProductMutation$data;
  variables: RateProductMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RateProductPayload",
    "kind": "LinkedField",
    "name": "rateProduct",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RateProductMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RateProductMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "aab2842ddb2309be42d410fe7061f5c5",
    "id": null,
    "metadata": {},
    "name": "RateProductMutation",
    "operationKind": "mutation",
    "text": "mutation RateProductMutation(\n  $input: RateProductInput!\n) {\n  rateProduct(input: $input) {\n    viewer {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0b6e3062c56f3d3c85d0e5276fea0405";

export default node;
