/**
 * @generated SignedSource<<14b502d4a99dc4283181bb0d7ae26544>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubstitutionsQuery_Query$variables = Record<PropertyKey, never>;
export type SubstitutionsQuery_Query$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"Substitutions_viewer">;
  } | null | undefined;
};
export type SubstitutionsQuery_Query = {
  response: SubstitutionsQuery_Query$data;
  variables: SubstitutionsQuery_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "productId",
    "storageKey": null
  },
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "package",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SubstitutionsQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Substitutions_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SubstitutionsQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Substitution",
                "kind": "LinkedField",
                "name": "substitutions",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rowId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "substitution",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c7c00c57f3dbba630117776dcbb5698",
    "id": null,
    "metadata": {},
    "name": "SubstitutionsQuery_Query",
    "operationKind": "query",
    "text": "query SubstitutionsQuery_Query {\n  viewer {\n    ...Substitutions_viewer\n    id\n  }\n}\n\nfragment Substitutions_viewer on Viewer {\n  id\n  user {\n    id\n    email\n    fullName\n    substitutions {\n      id\n      rowId\n      product {\n        id\n        productId\n        fullName\n        package\n      }\n      substitution {\n        id\n        productId\n        fullName\n        package\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d95431a275bf81da809460cb638e28c5";

export default node;
