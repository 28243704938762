import {DateTime} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import {useRouter} from 'found';
import get from 'lodash/get';
import {useCallback, useEffect, useState} from 'react';
import {useFragment} from 'react-relay';
import {Sticky, StickyContainer} from 'react-sticky';
import {Dimmer} from 'semantic-ui-react';
import styled, {createGlobalStyle} from 'styled-components';
import {WindowSizeContext} from '../helpers/withWindowSize';
import {Announcement} from './Announcement';
import {Banner} from './Banner';
import Cart from './Cart';
import Footer from './Footer/Footer';
import Header from './Header/Header';

const GlobalStyle = createGlobalStyle`
  body {
    overflow: ${(p) => (p.isCartOpen ? 'hidden' : 'visible')};
  }
`;

const Container = styled.div``;

const InnerContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
`;

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 3000;
`;

export const App = (props) => {
  const store = useFragment(
    graphql`
      fragment App_store on Store {
        messages {
          type
          body
          details
          persists
        }
      }
    `,
    props.store,
  );
  const viewer = useFragment(
    graphql`
      fragment App_viewer on Viewer {
        coop {
          announcement
          currentCycle {
            deadline
          }
          timezone
        }
        user {
          isBanned
          owed
          owedReason
        }
        ...Announcement_viewer
        ...Cart_viewer
        ...Header_viewer
      }
    `,
    props.viewer,
  );

  const {children, onBannerClose} = props;
  const [isCartOpen, setIsCartOpen] = useState(window.location.pathname.indexOf('/cart') === 0);
  const {router} = useRouter();

  useEffect(() => {
    window.onpopstate = window.history.onpushstate = ({state = {}} = {}) => setIsCartOpen((state && state.cart) || false);
  });

  const handleClickOutside = useCallback(() => {
    setIsCartOpen(false);
    if (window.location.pathname.indexOf('/cart') === 0) {
      if (document.referrer) {
        window.history.go(-1);
      } else {
        router.push('/');
      }
    }
  }, [router]);

  const messages = store?.messages || [];

  const timezone = viewer?.coop?.timezone || 'America/Chicago';
  const now = DateTime.utc();
  const deadline = DateTime.fromSQL(`${get(viewer, 'coop.currentCycle.deadline')} 23:59:59 ${timezone}`, {setZone: true});
  const diff = deadline.isValid && deadline.diff(now).as('days');
  const announcement = String((viewer.coop && viewer.coop.announcement) || '').trim();
  const hasAnnouncements = Boolean((diff > -3 && diff < 10) || announcement.length);

  return (
    <WindowSizeContext.Provider>
      <WindowSizeContext.Consumer>
        {({isMobile}) => (
          <Container>
            <GlobalStyle isCartOpen={isCartOpen} />
            <StickyContainer>
              <Dimmer.Dimmable dimmed={isCartOpen}>
                <Dimmer active={isCartOpen} onClickOutside={handleClickOutside}>
                  <Sidebar isOpen={isCartOpen} style={{width: isMobile ? '100%' : '600px'}}>
                    <Cart viewer={viewer} />
                  </Sidebar>
                </Dimmer>
                <InnerContainer>
                  {messages.length > 0 && <Banner messages={messages} onClose={onBannerClose} />}
                  {hasAnnouncements && <Announcement viewer={viewer} />}
                  <Sticky relative={false} topOffset={hasAnnouncements ? 57 : 0}>
                    {({style}) => <Header style={style} viewer={viewer} />}
                  </Sticky>
                  <Content>{children}</Content>
                  <Footer />
                </InnerContainer>
              </Dimmer.Dimmable>
            </StickyContainer>
          </Container>
        )}
      </WindowSizeContext.Consumer>
    </WindowSizeContext.Provider>
  );
};
