/**
 * @generated SignedSource<<f77d615c7ac09f93ef6fd02eff6d3331>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Home_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HomeCategories_viewer" | "Tabs_viewer">;
  readonly " $fragmentType": "Home_viewer";
};
export type Home_viewer$key = {
  readonly " $data"?: Home_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Home_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Home_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeCategories_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Tabs_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "96405ef24de4bb196394b9429cd1b313";

export default node;
