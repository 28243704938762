/**
 * @generated SignedSource<<cf1be8027b49f4333300e4812be3d3bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WhatsOnSale_viewer$data = {
  readonly cart: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly clearanceItemId: string | null | undefined;
          readonly productId: string | null | undefined;
          readonly specialItemId: string | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"ProductItem_cartItem">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ProductItem_cart">;
  } | null | undefined;
  readonly clearanceItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly cost: number | null | undefined;
        readonly id: string;
        readonly product: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"ProductItem_product">;
        } | null | undefined;
        readonly qty: number | null | undefined;
        readonly unit: number | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly specialItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly cost: number | null | undefined;
        readonly id: string;
        readonly product: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"ProductItem_product">;
        } | null | undefined;
        readonly unit: number | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ProductItem_viewer">;
  readonly " $fragmentType": "WhatsOnSale_viewer";
};
export type WhatsOnSale_viewer$key = {
  readonly " $data"?: WhatsOnSale_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"WhatsOnSale_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 8
  },
  {
    "kind": "Literal",
    "name": "offset",
    "value": 0
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      [
        "products.rank",
        "ASC"
      ]
    ]
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cost",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductItem_product"
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WhatsOnSale_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductItem_viewer"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProductItem_cart"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 10000
            }
          ],
          "concreteType": "CartItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CartItemEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CartItem",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProductItem_cartItem"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "clearanceItemId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "productId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "specialItemId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:10000)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "ClearanceItemConnection",
      "kind": "LinkedField",
      "name": "clearanceItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ClearanceItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClearanceItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "qty",
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "clearanceItems(limit:8,offset:0,orderBy:[[\"products.rank\",\"ASC\"]])"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "SpecialItemConnection",
      "kind": "LinkedField",
      "name": "specialItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SpecialItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SpecialItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "specialItems(limit:8,offset:0,orderBy:[[\"products.rank\",\"ASC\"]])"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "14303ef5411508e65b31bc087610189f";

export default node;
