/**
 * @generated SignedSource<<24173670a3bd3a055544d6866c68dcb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type App_store$data = {
  readonly messages: ReadonlyArray<{
    readonly body: string | null | undefined;
    readonly details: ReadonlyArray<string | null | undefined> | null | undefined;
    readonly persists: boolean | null | undefined;
    readonly type: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "App_store";
};
export type App_store$key = {
  readonly " $data"?: App_store$data;
  readonly " $fragmentSpreads": FragmentRefs<"App_store">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "App_store",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Message",
          "kind": "LinkedField",
          "name": "messages",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "body",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "details",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "persists",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Store",
  "abstractKey": null
};

(node as any).hash = "9701bc47472b4cc20eb419fb1131252f";

export default node;
