/**
 * @generated SignedSource<<ff510ba0ec2b19515f7f75a3d6764ded>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Tabs_viewer$data = {
  readonly coop: {
    readonly currentCycle: {
      readonly deadline: any | null | undefined;
      readonly pickup: any | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly user: {
    readonly role: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"DeliveryStatus_viewer" | "MySplits_viewer" | "OrderDates_viewer" | "PayMyBill_viewer" | "Reorder_viewer" | "WhatsOnSale_viewer">;
  readonly " $fragmentType": "Tabs_viewer";
};
export type Tabs_viewer$key = {
  readonly " $data"?: Tabs_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Tabs_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Tabs_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeliveryStatus_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MySplits_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderDates_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PayMyBill_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Reorder_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WhatsOnSale_viewer"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Coop",
      "kind": "LinkedField",
      "name": "coop",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Cycle",
          "kind": "LinkedField",
          "name": "currentCycle",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deadline",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pickup",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "5f0ed4cb2eac1f7acd361008130fd467";

export default node;
