/**
 * @generated SignedSource<<dd3a5ac9f359d767e35538c35d72caad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeleteImageInput = {
  imageId: string;
};
export type DeleteImageMutation$variables = {
  input: DeleteImageInput;
};
export type DeleteImageMutation$data = {
  readonly deleteImage: {
    readonly deletedImageId: string | null | undefined;
  } | null | undefined;
};
export type DeleteImageMutation = {
  response: DeleteImageMutation$data;
  variables: DeleteImageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteImagePayload",
    "kind": "LinkedField",
    "name": "deleteImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedImageId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteImageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteImageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e1c6c6a8fd6f359f02b8f5ceb3b80942",
    "id": null,
    "metadata": {},
    "name": "DeleteImageMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteImageMutation(\n  $input: DeleteImageInput!\n) {\n  deleteImage(input: $input) {\n    deletedImageId\n  }\n}\n"
  }
};
})();

(node as any).hash = "c1b5247e86da0c448640da7cb0c62684";

export default node;
