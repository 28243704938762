/**
 * @generated SignedSource<<a2c4929e7d7d0f3df10d248277ab5f91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ForgotInput = {
  email: string;
};
export type ForgotMutation$variables = {
  input: ForgotInput;
};
export type ForgotMutation$data = {
  readonly forgot: {
    readonly _empty: string | null | undefined;
  } | null | undefined;
};
export type ForgotMutation = {
  response: ForgotMutation$data;
  variables: ForgotMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ForgotPayload",
    "kind": "LinkedField",
    "name": "forgot",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_empty",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForgotMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForgotMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f1ec572a0078272e7827e4f910f7dd0a",
    "id": null,
    "metadata": {},
    "name": "ForgotMutation",
    "operationKind": "mutation",
    "text": "mutation ForgotMutation(\n  $input: ForgotInput!\n) {\n  forgot(input: $input) {\n    _empty\n  }\n}\n"
  }
};
})();

(node as any).hash = "4f2c3a815909e740f10401465d8c55e1";

export default node;
