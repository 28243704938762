import {Redirect, Route, makeRouteConfig} from 'found';
import Cookies from 'js-cookie';
import About from './components/About';
import Account from './components/Account';
import {App} from './components/App';
import Categories from './components/Categories';
import Checkout from './components/Checkout/Checkout';
import CheckoutThanks from './components/Checkout/Thanks';
import Contact from './components/Contact';
import Forgot from './components/Forgot';
import Help from './components/Help';
import Home from './components/Home/Home';
import Locations from './components/Locations';
import Login from './components/Login';
import Order from './components/Orders/Order';
import Orders from './components/Orders/Orders';
import Product from './components/Product';
import ClearanceItems from './components/Products/ClearanceItems';
import Products from './components/Products/Products';
import SpecialItems from './components/Products/SpecialItems';
import Ratings from './components/Ratings';
import Register from './components/Register';
import Settings from './components/Settings';
import Splits from './components/Splits';
import Substitutions from './components/Substitutions';
import {Terms} from './components/Terms';
import {CP_ACCESS_TOKEN} from './constants';
import {LogoutMutation} from './mutations/LogoutMutation';
import AppQuery from './queries/AppQuery';
import CategoriesQuery from './queries/CategoriesQuery';
import CheckoutQuery from './queries/CheckoutQuery';
import ClearanceItemsQuery from './queries/ClearanceItemsQuery';
import ContactQuery from './queries/ContactQuery';
import HomeQuery from './queries/HomeQuery';
import OrderQuery from './queries/OrderQuery';
import OrdersQuery from './queries/OrdersQuery';
import ProductQuery from './queries/ProductQuery';
import ProductsQuery from './queries/ProductsQuery';
import RatingsQuery from './queries/RatingsQuery';
import RegisterQuery from './queries/RegisterQuery';
import SettingsQuery from './queries/SettingsQuery';
import SpecialItemsQuery from './queries/SpecialItemsQuery';
import SplitsQuery from './queries/SplitsQuery';
import SubstitutionsQuery from './queries/SubstitutionsQuery';
import TermsQuery from './queries/TermsQuery';

const logout = ({props}) => {
  const accessToken = Cookies.get(CP_ACCESS_TOKEN);

  const payload = {
    input: {
      accessToken,
    },
  };

  const onSuccess = (response) => {
    Cookies.remove(CP_ACCESS_TOKEN);
    props.router.push('/');
    window.location.reload();
  };

  const onFailure = () => {
    onSuccess();
    // router.push('/');
    // window.location.reload();
  };

  LogoutMutation(payload, onSuccess, onFailure);
};

const authenticate = ({Component, data, match, props}) => {
  const currentLocation = match.location.pathname;
  const accessToken = Cookies.get(CP_ACCESS_TOKEN);
  if (!accessToken) {
    return match.router.replace(`/login?redirect=${currentLocation}`);
  }
};

export default makeRouteConfig(
  <Route Component={App} path="/" query={AppQuery}>
    <Route Component={Categories} path="categories" query={CategoriesQuery} />

    <Route Component={Home} query={HomeQuery} />

    <Route render={authenticate}>
      <Redirect from="clearance" to="categories/clearance" />
      <Route
        Component={ClearanceItems}
        path="categories/clearance"
        prepareVariables={ClearanceItems.getInitialVariables}
        query={ClearanceItemsQuery}
      />
      <Redirect from="specials" to="categories/specials" />
      <Route
        Component={SpecialItems}
        path="categories/specials"
        prepareVariables={SpecialItems.getInitialVariables}
        query={SpecialItemsQuery}
      />
    </Route>
    <Route
      Component={Products}
      path="categories/:category?/:subcategory?/:subsubcategory?"
      prepareVariables={Products.getInitialVariables}
      query={ProductsQuery}
    />
    <Redirect from="p/:productId/:variantId?" to={`/products/:productId/:variantId?${window.location.hash}`} />
    <Redirect from="product/:productId/:variantId?" to={`/products/:productId/:variantId?/${window.location.hash}`} />
    <Route Component={Product} path="products/:productId/:variantId?" prepareVariables={Product.getInitialVariables} query={ProductQuery} />
    <Route Component={Products} path="search" prepareVariables={Products.getInitialVariables} query={ProductsQuery} />

    <Route Component={Login} path="login" />
    <Route Component={Forgot} path="forgot" />
    <Route path="logout" render={logout} />
    <Route Component={Register} path="register" query={RegisterQuery} />

    <Route Component={About} path="about" />
    <Route Component={Contact} path="contact" query={ContactQuery} />
    <Route Component={Help} path="help" />

    <Route Component={Locations} path="locations" />

    <Route Component={Terms} path="terms" query={TermsQuery} />

    <Route render={authenticate}>
      <Route Component={Home} path="specials" query={HomeQuery} />

      <Route Component={Home} path="cart" query={HomeQuery} />
      <Route Component={CheckoutThanks} path="pay/thanks" />
      <Route Component={Checkout} path="(checkout|pay)/:orderId?" query={CheckoutQuery} />

      <Redirect from="orders" to="/account/orders" />
      <Redirect from="splits" to="/account/splits" />
      <Route Component={Account} path="account">
        <Redirect from="" to="/account/profile" />
        <Route Component={Settings} path="profile" query={SettingsQuery} />
        <Route Component={Orders} path="orders" query={OrdersQuery} />
        <Route Component={Ratings} path="ratings" query={RatingsQuery} />
        <Route Component={Substitutions} path="substitutions" query={SubstitutionsQuery} />
        <Route Component={Splits} path="splits" query={SplitsQuery} />
      </Route>
      <Route Component={Order} path="orders/:orderId" query={OrderQuery} />
    </Route>
  </Route>,
);
