import {graphql} from 'babel-plugin-relay/macro';
import {useFragment} from 'react-relay';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px 0 40px 0;
  min-height: 100vh;
  background: #f7f7f7;
`;

const Content = styled.div`
  margin: 0 auto;
  padding: 25px;
  max-width: 960px;
  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;
`;

export const Terms = (props) => {
  const viewer = useFragment(
    graphql`
      fragment Terms_viewer on Viewer {
        terms
      }
    `,
    props.viewer,
  );

  const terms = (viewer && viewer.terms) || 'N/A';

  return (
    <Container>
      <Content>
        <div dangerouslySetInnerHTML={{__html: terms.content}} />.
      </Content>
    </Container>
  );
};
