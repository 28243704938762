/**
 * @generated SignedSource<<bdf3aa906765f85c9e756e6876afdd95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Deadlines_viewer$data = {
  readonly coop: {
    readonly currentCycle: {
      readonly deadline: any | null | undefined;
      readonly deliveryStatus: string | null | undefined;
      readonly pickup: any | null | undefined;
      readonly pickupStart: string | null | undefined;
      readonly pickupStreet: string | null | undefined;
      readonly pickupZip: string | null | undefined;
    } | null | undefined;
    readonly nextCycle: {
      readonly deadline: any | null | undefined;
      readonly pickup: any | null | undefined;
      readonly pickupStart: string | null | undefined;
      readonly pickupStreet: string | null | undefined;
      readonly pickupZip: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly user: {
    readonly role: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "Deadlines_viewer";
};
export type Deadlines_viewer$key = {
  readonly " $data"?: Deadlines_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Deadlines_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadline",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickup",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupStart",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupStreet",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupZip",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Deadlines_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Coop",
      "kind": "LinkedField",
      "name": "coop",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Cycle",
          "kind": "LinkedField",
          "name": "currentCycle",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deliveryStatus",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Cycle",
          "kind": "LinkedField",
          "name": "nextCycle",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "e090eb1e7b99d8e7d1c015dcbb35fb49";

export default node;
