import Cookies from 'js-cookie';
import {
  RelayNetworkLayer,
  authMiddleware,
  errorMiddleware,
  retryMiddleware,
  uploadMiddleware,
  urlMiddleware,
} from 'react-relay-network-modern/es';
import {Environment, RecordSource, Store, commitLocalUpdate} from 'relay-runtime';
import {CP_ACCESS_TOKEN} from './constants';

const GRAPHQL_SERVER_URL = global.__DEV__ ? '//localhost:3001/graphql' : '/graphql';

// Environment
const networkMiddlewares = [
  urlMiddleware({
    url: (req) => {
      return Promise.resolve(GRAPHQL_SERVER_URL);
    },
  }),
  // global.__DEV__ ? loggerMiddleware() : null,
  global.__DEV__ ? errorMiddleware() : null,
  // global.__DEV__ ? perfMiddleware() : null,
  retryMiddleware({
    // [3200, 6400, 12800, 25600, 51200, 102400, 204800, 409600]
    beforeRetry: ({abort, attempt, delay, forceRetry, lastError, req}) => {
      window.forceRelayRetry = forceRetry;
      console.log('call `forceRelayRetry()` for immediately retry! Or wait ' + delay + ' ms.');
    },

    fetchTimeout: 10000,
    retryDelays: (attempt) => Math.pow(2, attempt + 4) * 100,
    statusCodes: [500, 503, 504],
  }),
  authMiddleware({
    token: () => Cookies.get(CP_ACCESS_TOKEN),
    // TODO: Refetch token
  }),
  uploadMiddleware(),
  (next) => async (req) => {
    // TODO: Send request ID to identify the request if error occurs
    const res = await next(req);
    return res;
  },
];

const networkOptions = {
  noThrow: true,
};

const network = new RelayNetworkLayer(networkMiddlewares, networkOptions);

const store = new Store(new RecordSource());

export const environment = new Environment({network, store});

// Initilize the local Store
commitLocalUpdate(environment, (store) => {
  const dataID = 'client:store';
  const record = store.create(dataID, 'Store');

  record.setLinkedRecords([], 'messages');

  // HACK: Prevent Relay from garbage collecting the record
  environment.retain({
    request: {identifier: 0},
    root: {
      dataID,
      node: {selections: []},
      variables: {},
    },
  });

  store.getRoot().setLinkedRecord(record, 'store');
});

//

export default environment;
