import styled from 'styled-components';
import {go} from '../helpers';

const Container = styled.div`
  padding: 20px 0 40px 0;
  min-height: 100vh;
  background: #f7f7f7;
`;

const Content = styled.div`
  margin: 0 auto;
  padding: 25px;
  max-width: 1360px;
  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;

  & div {
    display: flex;
    align-items: start;
  }

  & iframe {
    margin-right: 1em;
  }

  & ul {
    margin: 0 0 3em 0;
    padding: 0;
    width: 30%;

    display: flex;
    flex-wrap: wrap;

    & li {
      margin: 0;
      padding: 0;
      width: 50%;
      list-style: none;
    }
  }
`;

const Locations = (props) => {
  return (
    <Container>
      <Content>
        <h2>Co-op Locations</h2>
        <div>
          <iframe
            height="600"
            src="https://www.google.com/maps/d/embed?mid=1RcUFOOn0hkq2nP-UG7NR_lJonHDUnXHU&z=4"
            title="Co-op Communities"
            width="100%"
          />
          <ul>
            {[
              'Albuquerque, NM',
              'Atlanta, GA',
              'Bentonville, AR',
              'Berkeley, CA',
              'Boca Raton, FL',
              'Charleston, SC',
              'Charlotte, NC',
              'Cincinnati, OH',
              'Cleveland, OH',
              'Columbus, OH',
              'Dallas, TX',
              'Denver, CO',
              'Detroit, MI',
              'El Paso, TX',
              'Henderson, NV',
              'Hollywood, FL',
              'Houston, TX',
              'Indianapolis, IN',
              'Irvine, CA',
              'Las Vegas, NV',
              'Little Rock, AR',
              'Long Beach, CA',
              'Louisville, KY',
              'Memphis, TN',
              'Minneapolis, MN',
              'Nashville, TN',
              'Omaha, NE',
              'Overland Park, KS',
              'Phoenix, AZ',
              'Plano, TX',
              'Portland, OR',
              'Raleigh, NC',
              'San Antonio, TX',
              'Savannah, GA',
              'Seattle, WA',
              'Silicon Valley, CA',
              'St. Louis, MO',
              'Tucson, AZ',
            ].map((coop) => {
              return (
                <li>
                  <a href={'/register?coop=' + coop} onClick={go('/register?coop=' + coop, {router: props.router})}>
                    {coop}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </Content>
    </Container>
  );
};

export default Locations;
