/**
 * @generated SignedSource<<70e8de06e456a5dcf7aeca27fb183e95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpdateCartItemInput = {
  id: string;
  qty?: number | null | undefined;
};
export type UpdateCartItemMutation$variables = {
  input: UpdateCartItemInput;
};
export type UpdateCartItemMutation$data = {
  readonly updateCartItem: {
    readonly cart: {
      readonly credit: number | null | undefined;
      readonly owed: number | null | undefined;
      readonly qty: number | null | undefined;
      readonly shipping: number | null | undefined;
      readonly shippingRate: number | null | undefined;
      readonly subtotal: number | null | undefined;
      readonly tax: number | null | undefined;
      readonly taxRate: number | null | undefined;
      readonly total: number | null | undefined;
    } | null | undefined;
    readonly cartItem: {
      readonly clearanceItem: {
        readonly id: string;
        readonly qty: number | null | undefined;
      } | null | undefined;
      readonly product: {
        readonly id: string;
      } | null | undefined;
      readonly qty: number | null | undefined;
      readonly total: number | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type UpdateCartItemMutation = {
  response: UpdateCartItemMutation$data;
  variables: UpdateCartItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "qty",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ClearanceItem",
  "kind": "LinkedField",
  "name": "clearanceItem",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtotal",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shipping",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shippingRate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tax",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxRate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "credit",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "owed",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCartItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCartItemPayload",
        "kind": "LinkedField",
        "name": "updateCartItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CartItem",
            "kind": "LinkedField",
            "name": "cartItem",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v6/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCartItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCartItemPayload",
        "kind": "LinkedField",
        "name": "updateCartItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CartItem",
            "kind": "LinkedField",
            "name": "cartItem",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v6/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff44fb4518047500bbca3582814b3b28",
    "id": null,
    "metadata": {},
    "name": "UpdateCartItemMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCartItemMutation(\n  $input: UpdateCartItemInput!\n) {\n  updateCartItem(input: $input) {\n    cartItem {\n      clearanceItem {\n        id\n        qty\n      }\n      product {\n        id\n      }\n      qty\n      total\n      id\n    }\n    cart {\n      qty\n      subtotal\n      shipping\n      shippingRate\n      tax\n      taxRate\n      total\n      credit\n      owed\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6048ff0655f7d0eb24d4d6c340889fd7";

export default node;
