/**
 * @generated SignedSource<<a892327ac11a2b1fbba84c67b8584c68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderItemRow_orderItem$data = {
  readonly clearanceItemId: string | null | undefined;
  readonly product: {
    readonly cost: number | null | undefined;
    readonly fullName: string | null | undefined;
    readonly image: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly package: string | null | undefined;
    readonly productId: string | null | undefined;
  } | null | undefined;
  readonly qty: number | null | undefined;
  readonly specialItemId: string | null | undefined;
  readonly total: number | null | undefined;
  readonly " $fragmentType": "OrderItemRow_orderItem";
};
export type OrderItemRow_orderItem$key = {
  readonly " $data"?: OrderItemRow_orderItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderItemRow_orderItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderItemRow_orderItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clearanceItemId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialItemId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qty",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "package",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderItem",
  "abstractKey": null
};

(node as any).hash = "da40919fe38a1da033ef08d58a3293fd";

export default node;
